import React from "react"

export default function ReviewLeft({ name, position, img, children }) {
    return (
        <div className="flex flex-col md:flex-row md:space-x-10">
            <a
                className="flex flex-col items-center flex-shrink-0 mb-5 text-xl md:mb-0"
                href="https://twitter.com/Ben0xA/status/1163787511249985536"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    className="w-32 h-32 mb-4 transition-all transform border rounded-full shadow-lg border-blue-50 hover:shadow-xl hover:scale-105"
                    src={img}
                    alt={name}
                />
                <p className="font-semibold">{name}</p>
                <p className="font-thin">{position}</p>
            </a>
            <p className="p-10 text-xl text-white bg-blue-300 rounded-lg shadow-lg rect-left">
                <svg
                    className="inline w-8 h-8 mr-5 text-white/50"
                    viewBox="0 0 26 28"
                    fill="currentColor"
                >
                    <path d="M12 15v6c0 1.656-1.344 3-3 3h-6c-1.656 0-3-1.344-3-3v-11c0-4.406 3.594-8 8-8h1c0.547 0 1 0.453 1 1v2c0 0.547-0.453 1-1 1h-1c-2.203 0-4 1.797-4 4v0.5c0 0.828 0.672 1.5 1.5 1.5h3.5c1.656 0 3 1.344 3 3zM26 15v6c0 1.656-1.344 3-3 3h-6c-1.656 0-3-1.344-3-3v-11c0-4.406 3.594-8 8-8h1c0.547 0 1 0.453 1 1v2c0 0.547-0.453 1-1 1h-1c-2.203 0-4 1.797-4 4v0.5c0 0.828 0.672 1.5 1.5 1.5h3.5c1.656 0 3 1.344 3 3z"></path>
                </svg>
                {children}
            </p>
        </div>
    )
}
