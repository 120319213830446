import React from "react"

export default function ImageRight({ image, children }) {
  return (
    <div className="flex flex-col-reverse items-center pt-10 md:flex-row md:pt-20">
      <div className="space-y-3 md:w-1/2">{children}</div>
      <div className="mb-5 md:w-1/2 md:mb-0">
        <img src={image} alt="" />
      </div>
    </div>
  )
}
