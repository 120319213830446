import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Features() {
  return (
    <section className="container grid grid-cols-2 md:flex justify-center items-center py-10 md:py-20">
      <StaticImage src="../images/1.png" alt="" />
      <StaticImage src="../images/2.png" alt="" />
      <StaticImage src="../images/3.jpg" alt="" />
      <StaticImage src="../images/4.png" alt="" />
    </section>
  )
}
