import React from "react"
import NanoHero from "../components/NanoHero"
import ImageLeft from "../components/Blocks/ImageLeft"
import ImageRight from "../components/Blocks/ImageRight"
import ReviewLeft from "../components/Blocks/ReviewLeft"
import ReviewRight from "../components/Blocks/ReviewRight"
import Features from "../components/Features"
import Seo from "../components/SEO"
import Mailchimp from "../components/Mail/Mailchimp"

import nanoImg1 from "../images/nanoImg1.png"
import nanoImg2 from "../images/nanoImg2.png"

import migrationImg2 from "../images/migration2.png"
import rocketImg from "../images/rocket.png"
import personImg from "../images/person.png"
import brianImg from "../images/brian.jpg"
import paulImg from "../images/Paul_Franco.jpg"

const items = [
    {
        image: migrationImg2,
        title: "Lift Heavier Loads",
        description:
            "Deploy both classic VMs and bare-metal containers side-by-side. Use a fraction of the hardware resources as a full virtual machine.",
    },
    {
        image: rocketImg,
        title: "Work at Lightning Speed",
        description:
            "Our file system guards against data loss. You can also take snapshots and create templates in seconds without using additional storage.",
    },
    {
        image: personImg,
        title: "Easiest On-Prem Cloud",
        description:
            "antMan is the easiest cloud manager out there. You can spin up your first virtual servers within seconds and feel like a cloud expert.",
    },
]

export default function AntsleNanoPage() {
    return (
        <div>
            <Seo title="Antsle Nano" />
            <NanoHero />
            <section className="container py-10 md:py-20">
                <ImageRight image={nanoImg1}>
                    <div className="space-y-5">
                        <h2 className="text-3xl font-bold">
                            Classic On-Prem Sucks
                        </h2>
                        <p>
                            Have you ever gotten tired of paying out of the nose
                            for your dedicated server in the cloud? What were
                            your options for an on-prem cloud?
                        </p>
                        <p>
                            Chances are you bought a server, started installing{" "}
                            <b>complicated software</b>, researched third party
                            back up options, and hopefully backed out before{" "}
                            <b>pulling your hair out.</b>
                        </p>
                    </div>
                </ImageRight>
                <ImageLeft image={nanoImg2}>
                    <div className="space-y-5">
                        <h2 className="text-3xl font-bold">
                            Can't it Be Simple?
                        </h2>
                        <p>
                            Choosing Antsle is choosing{" "}
                            <b>radical simplicity.</b> The Nano is preconfigured
                            so that you can spin up virtual servers and deploy
                            them just like you would on the public cloud.
                        </p>
                        <ol className="list-disc list-inside">
                            <li>
                                Run <b>Raspbian</b> in one
                            </li>
                            <li>
                                <b>CentOS</b> in a second
                            </li>
                            <li>
                                <b>Name server</b> in a third
                            </li>
                            <li>
                                <b>Web server</b> on another{" "}
                            </li>
                        </ol>
                    </div>
                </ImageLeft>
            </section>
            <section className="container py-10 md:py-20">
                <h2 className="text-3xl font-bold text-center">
                    See antMan in Action
                </h2>
                <img
                    src="https://go.antsle.com/wp-content/uploads/2020/02/New-UI-Recording-Gif.gif"
                    alt=""
                    className="pt-10 mx-auto shadow-md"
                />
            </section>
            <section className="py-10 bg-gray-50 md:py-20">
                <div className="container">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold text-gray-800 md:text-5xl">
                            How Does antMan Make You a Super Hero?
                        </h1>
                    </div>
                    <div className="flex flex-col items-center justify-between pt-10 space-y-10 md:space-y-0 md:flex-row">
                        {items.map((item, i) => (
                            <div
                                key={i}
                                className="flex flex-col items-center justify-center space-y-5"
                            >
                                <img
                                    className="w-20 h-auto"
                                    src={item.image}
                                    alt={item.title}
                                />
                                <p className="text-2xl font-bold text-gray-800">
                                    {item.title}
                                </p>
                                <p className="text-center md:w-1/2">
                                    {item.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="py-10 bg-gray-100 md:py-20">
                <div className="container">
                    <p className="text-3xl font-bold text-center text-gray-800">
                        Love for Antsle in the Wild
                    </p>
                    <div className="max-w-4xl py-10 mx-auto space-y-10">
                        <ReviewLeft
                            name="Paul Franco"
                            position="App Developer"
                            img={paulImg}
                        >
                            Deploying my NodeJS RESTful API’s to my
                            #PrivateCloud with @antsle_inc is just so freaking
                            easy.
                        </ReviewLeft>
                        <ReviewRight
                            name="Brian Magrogan"
                            position="Sr. Systems Engineer at Verrex"
                            img={brianImg}
                        >
                            Antsle is extremely easy to setup and use. The
                            templates that are already in place make deployment
                            very easy. Once you have customized them to you it
                            is a breeze to duplicate [virtual servers] for
                            whatever you need. The interface is intuitive and
                            easy to navigate around.
                        </ReviewRight>
                    </div>
                </div>
            </section>
            <Features />
            <section className="bg-[rgb(254,229,72)]">
                <div className="container py-10 space-y-5 text-center md:py-20">
                    <p className="mx-auto text-2xl font-bold md:w-2/3">
                        Requested an invite and still waiting? Please allow a
                        few hours for processing and we will let you know if you
                        are approved.{" "}
                    </p>
                    <p className="text-xl">
                        Didn't request an invite yet? Request to join the early
                        adopters!
                    </p>
                    <Mailchimp
                        className="container flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-10"
                        btnClassName="!bg-black"
                        btnText="Get Your Invitation"
                    />
                </div>
            </section>
        </div>
    )
}
