import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Mailchimp from "./Mail/Mailchimp"

export default function NanoHero() {
    return (
        <>
            <section className="relative h-[500px] md:h-[800px] flex items-end md:items-center md:justify-end">
                <StaticImage
                    className="absolute object-cover w-full h-full"
                    src="../images/nanoBg.jpg"
                />
                <div className="absolute w-full h-full nanoBgGradient"></div>
                <div className="relative z-10 p-5 space-y-5 text-white md:p-0 md:w-1/3 md:mx-28">
                    <h1 className="text-3xl font-bold md:text-5xl">
                        What if your virtual servers were so light, you could
                        hold them on your fingertips?{" "}
                    </h1>
                    <p className="text-xl md:text-2xl">
                        Introducing the #1 private cloud platform that fits in
                        your pocket.{" "}
                    </p>
                    <p className="text-xl md:text-2xl">
                        ​Available for new users by invite only.{" "}
                    </p>
                </div>
            </section>
            <section className="py-10 bg-[rgb(254,229,72)]">
                <Mailchimp
                    className="container flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-10"
                    btnClassName="!bg-black"
                    btnText="Get Your Invitation"
                />
            </section>
        </>
    )
}
